import React from 'react';

import s from './Intro.scss';

interface IProps {
  children: React.ReactNode;
  separator?: boolean;
}

export const Intro = ({ children, separator }: IProps) => (
  <div className={s('intro', { hasSeparator: separator })}>
    <div className={s.intro__container}>
      <div className={s.intro__inner}>
        {children}
        {separator && (<hr className={s.intro__separator} />)}
      </div>
    </div>
  </div>
);
