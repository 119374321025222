import React, { Children } from 'react';
import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';

import s from './FlightGrid.scss';

export const FlightGrid = (props: {children: React.ReactNode}) => (
  <Container>
    <Row>
      {Children.map(props.children, (child, i) => (
        <div className={s.flightGrid__item} key={`${s.flightGrid__item}-${i}`}>{child}</div>
      ))}
    </Row>
  </Container>
);
