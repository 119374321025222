import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';

import Chevron from 'assets/svg/icons/chevron.svg';

import { selectStyles } from './SelectStyles';

import s from './LargeSelect.scss';

interface IProps {
  children: React.ReactNode;
  onChange: (e: any) => void;
  defaultValue: string;
  className?: string;
}

export const LargeSelect = ({ className, children, defaultValue, onChange }: IProps) => {

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {<Chevron className={s.largeSelect__chevron} />}
      </components.DropdownIndicator>
    );
  };
  const selectedIndex = children.map((e) => e.value ).indexOf(defaultValue);

  const defaultDefaultValue = {
    label: '…',
    value: '',
  };

  return (
    <div className={s(className, 'largeSelect')}>
      <FormattedMessage id="travelling">
        {(message) =>
          <label>
            {message as string}
              <Select
                defaultValue={children[selectedIndex] || defaultDefaultValue}
                styles={selectStyles}
                options={children}
                components={{ DropdownIndicator }}
                onChange={onChange}
                isSearchable={false}
                hideSelectedOptions={true}
              />
          </label>
        }
      </FormattedMessage>
    </div>
  );
};
