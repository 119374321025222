import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { get, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import createLink from 'utils/createLink';

import { MetaContainer } from 'containers/meta/MetaContainer';
import { SocialContainer } from 'containers/social/Social';

import { Caption } from 'components/caption/Caption';
import { Content } from 'components/content/Content';
import { Segment } from 'components/layout/Segment';
import { FlightCard } from 'components/flights/FlightCard';
import { FlightGrid } from 'components/flights/FlightGrid';
import { Image } from 'components/image/Image';
import { Intro } from 'components/intro/Intro';
import { LargeSelect } from 'components/large-select/LargeSelect';
import { Link } from 'components/link/Link';
import { PageHeader } from 'components/page-header/PageHeader';
import { PrismicImage } from 'components/prismic-image/PrismicImage';
import RichText from 'components/rich-text/RichText';
import { fragmentMeta } from 'fragments';

const DEFAULT_COUNTRY = {
  uid: '',
  title: '',
};

interface IProps {
  data: any;
  location: any;
}

const Flights = (props: IProps) => {
  const { data } = props;
  const pageData = get(data, 'prismic.allFlights_to_icelands.edges.0.node', {});
  const countries = get(data, 'prismic.allCountrys.edges', []);

  const heroImage = !isEmpty(pageData.image) && !isEmpty(pageData.image.x1) ?
    <PrismicImage image={pageData.image} view="hero" /> : undefined;

  const [activeFilter, setActiveFilter] = useState(get(props.location, 'search', '').replace('?q=', '') || DEFAULT_COUNTRY.uid);

  useEffect(() => {
    if (activeFilter) {
      const { pathname } = props.location;
      window.history.pushState({}, '', `${pathname}?q=${activeFilter}`);
    }
  }, [activeFilter]);

  const selectedCountry = countries.find(({ node }: any) => node._meta.uid === activeFilter);

  const filterChange = (e) => {
    const { value } = e;

    setActiveFilter(value);
  };

  // Render select
  const renderFilter = ({ node: item }: any) => {
    return { value: item._meta.uid, label: RichText.asText(item.title) };
  };

  const renderCountry = ({ node }: any) => {
    const renderAirline = ({ item, all_year, seasonal }: any) =>  {
      if (isEmpty(item)) { return null; }

      return (
          <FlightCard
            key={item._meta.id}
            heading={RichText.asText(item, 'title')}
            image={(!isEmpty(item.image) && !isEmpty(item.image.x2)) ?
              <Image src={item.image.x2.url} alt={item.image.alt || ''} /> : undefined}
            link={item.link.url}
          >
            {all_year && (
              <>
                <h3><FormattedMessage id="all-year" /></h3>
                <p>{RichText.asText(all_year)}</p>
              </>
            )}
            {seasonal && (
              <>
                <h3><FormattedMessage id="seasonal" /></h3>
                <p>{RichText.asText(seasonal)}</p>
              </>
            )}
          </FlightCard>
      );
    };

    const description = node.description;

    return (
        <React.Fragment key={node._meta.uid}>
          {description && (
            <Segment>
              <Intro>
                {RichText.render(description)}
              </Intro>
            </Segment>
          )}

          <Segment>
            <FlightGrid>
              {node.list.map(renderAirline)}
            </FlightGrid>
          </Segment>
      </React.Fragment>
    );
  };

  return (
    <>
      <MetaContainer data={pageData} />

      <Content>
        <SocialContainer type="sidebar" facebookLink="/" twitterLink="/" pinterestLink="/" />

        <PageHeader
          heading={RichText.asText(pageData.title)}
          text={RichText.asText(pageData.description)}
          backLink={!isEmpty(pageData.back_link) ? (
            <Link to={createLink(pageData.back_link)}>
              {RichText.asText(pageData.back_link, 'title')}
            </Link>
          ) : undefined}
          image={heroImage}
          imageCaption={
            <Caption
              align="right"
              position="inside"
              caption={RichText.asText(pageData, 'image_caption') || get(pageData.image, 'alt', '')}
              credits={RichText.asText(pageData, 'image_author') || get(pageData.image, 'copyright', '')}
            />
          }
          form={
            <div>
              <LargeSelect
                onChange={filterChange}
                defaultValue={activeFilter}
              >
                {countries.map(renderFilter)}
              </LargeSelect>
            </div>
          }
          overflow
        />

      {!isEmpty(selectedCountry) && renderCountry(selectedCountry)}

      </Content>
    </>
  );
};

export const query = graphql`
  query flight($lang: String!) {
    prismic {
      allFlights_to_icelands(lang: $lang) {
        edges {
          node {
            _meta {
              ...meta
            }
            title
            description
            image
            image_caption
            image_author
            back_link {
              _linkType
              ... on PRISMIC_Things_to_do {
                title
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_About_iceland {
                title
                _meta {
                  ...meta
                }
              }
              ... on PRISMIC_Plan_your_trip {
                title
                _meta {
                  ...meta
                }
              }
            }
            seo_title
            seo_description
            seo_image
          }
        }
      }
      allCountrys(lang: $lang) {
        edges {
          node {
            _meta {
              ...meta
            }
            title
            description
            list {
              all_year
              seasonal
              item {
                ... on PRISMIC_Airline {
                  _linkType
                  _meta {
                    id
                  }
                  title
                  image
                  link {
                    _linkType
                    __typename
                    ... on PRISMIC__ExternalLink {
                      url
                      _linkType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

Flights.fragments = [fragmentMeta];

export default Flights;
