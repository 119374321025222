/* eslint-disable */
const fontColor = '#ffffff';
const backgroundColor = '#161616';
const minTablet = '780px';

export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#d4d4d4' : fontColor,
    padding: '6px 20px 6px 20px',
    backgroundColor,
    cursor: 'pointer',
    '&:hover': {
      color: '#d4d4d4',
    },
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    position: 'relative',
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    color: fontColor,
    borderBottom: `2px solid ${fontColor}`,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: fontColor,
  }),
  indicatorSeparator: () => ({
    border: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    border: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    display: 'inline',
  }),
  input: (provided) => ({
    ...provided,
    padding: '0',
    margin: '0',
  }),
  singleValue: (provided, state) => {
    const transition = 'opacity 300ms';
    const color = fontColor;
    return { ...provided, color, transition };
  },
  container: () => ({
    display: 'inline-flex',
    width: '242px',
    '@media (min-width: 780px)': {
      marginLeft: '6px',
      width: '282px',
    },
  }),
  menu: () => ({
    position: 'absolute',
    top: '80%',
    marginLeft: '-18px',
    padding: '0px 0px 0px',
    width: '260px',
    backgroundColor: backgroundColor,
    zIndex: 11,
    textAlign: 'left',
    '@media (min-width: 780px)': {
      top: '68%',
      width: '300px',
    },
  }),
};
