import React, { cloneElement } from 'react';

import s from './FlightCard.scss';

interface IProps {
  image?: React.ReactElement<any>;
  heading: string;
  children: React.ReactNode;
  link: string;
}

export const FlightCard = ({ image, heading, children, link }: IProps) => (
  <div className={s('flightCard')}>
    <div className={s.flightCard__inner}>
      {image && (
        <div className={s.flightCard__imageWrap}>
          {cloneElement(image, { className: s.flightCard__image})}
        </div>
      )}
      <h2 className={s.flightCard__heading}>
        <a className={s.flightCard__link} href={link} target="_blank" rel="noopener noreferrer">{heading}</a></h2>
        <div className={s.flightCard__content}>
          {children}
        </div>
    </div>
  </div>
);
